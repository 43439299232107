.modal_loading {
    position: fixed;
    left: 0;
    top: 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background-color: rgba(255,255,255,.75);
}

.modal__bars {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
}

.modal__bar {
    width: 20px;
    background-color: #00f;
    border-radius: 5px;
    animation: bar_height 1.2s infinite;
}

.modal__bar:not(:last-of-type) {
    margin-right: 10px;
}

.modal__bar:nth-of-type(1) {
    animation-delay: 0;
}
.modal__bar:nth-of-type(2) {
    animation-delay: 0.15s;
}
.modal__bar:nth-of-type(3) {
    animation-delay: 0.3s;
}
.modal__bar:nth-of-type(4) {
    animation-delay: 0.45s;
}
.modal__bar:nth-of-type(5) {
    animation-delay: 0.6s;
}

@keyframes bar_height {
    0% {
        height: 20px;
    }
    
    50% {
        height: 60px;
    }
    100% {
        height: 20px;
    }
}

.App {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
}

main {
  display: flex;
  flex-direction: row;
  transition: transform 0.5s;
}

main.moved {
  transform: translate(-50%);
}

.test {
  width: 100vw;
  flex-shrink: 0;
}
.main_container.moved .feed {
    height: 0;
    overflow: hidden;
    padding: 0;
    /* transition-property: height;
    transition-delay: 0.5s; */
}

.feed {
    width: 100vw;
    padding: 20px;
    margin: 0 auto;
    flex-shrink: 0;
    /* transition-property: height;
    transition-delay: 0.5s; */
}
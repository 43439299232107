header {
    height: 70px;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
}

.navigation {
    height: 100%;
}

.navigation__list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    list-style-type: none;
    height: 100%;
}

.navigation__item {
    height: 100%;
}

.navigation__button {
    height: 100%;
    display: block;
    border-bottom: 2px solid transparent;
    border: none;
    background-color: #fff;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    font-size: 1.25em;
    cursor: pointer;
}

.navigation__button.active {
    border-bottom: 2px solid #00f;
}

.navigation__button:hover {
    background-color: #f6f6f6;
}



.post {
    width: 100%;
    border: 1px solid #eee;
    background-color: #fff;
    max-width: 775px;
    margin: 0 auto;
}

.post:not(:last-of-type) {
    margin-bottom: 20px;
}

.post__username {
    padding: 20px;
    margin: 0;
    text-align: left;
}

.post__username span {
    font-weight: 700;
}

.post__title {
    text-align: left;
    padding: 10px 20px 20px 20px;
    margin: 0;
}

.post__content {
    text-align: left;
    margin: 0;
    padding: 10px 20px 20px 20px;
    white-space: pre-wrap;
}

.post__rating {
    border-top: 1px solid #eee;
    padding: 10px 20px 20px 20px;
}

.post__rating__button {
    border: none;
    padding: 10px;
    cursor: pointer;
}

.post__rating__button.upvote {
    background-color: #aea;
}

.post__rating__button.downvote {
    background-color: #eaa;
}

.post__rating span {
    margin: 0 15px;
}
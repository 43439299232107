.form_section {
    margin: 20px auto 0 auto;
    padding: 20px;
    width: 100%;
    max-width: 775px;
    border: 1px solid #eee;
    background-color: #fff;
    margin-bottom: 20px;
}

h1 {
    margin: 0 0 20px 0;
    padding: 0;
}

form {
    display: block;
}

label {
    display: flex;
    flex-direction: column;
}

label:not(:last-of-type) {
    margin-bottom: 20px;
}

label input,
label textarea {
    margin-top: 10px;
    padding: 5px;
}

label textarea {
    resize: vertical;
}

label input.warning,
label textarea.warning {
    border: 1px solid #f00;
    background-color: #ffeeee;
}

.form_section button {
    background-color: #33f;
    border: none;
    padding: 15px 10px;
    width: 100%;
    max-width: 200px;
    color: #fff;
    margin-top: 20px;
    cursor: pointer;
}

.form__message {
    margin: 20px 0 0 0;
    padding: 10px;
}

.form__message.success {
    border-left: 5px solid #0f0;
    background-color: #dfd;
}

.form__message.warning {
    border-left: 5px solid #f00;
    background-color: #fdd;
}